import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/common/alert.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { LoginService } from 'src/app/services/login.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { ResourceService } from 'src/app/services/common/resource.service';
import { WishListService } from 'src/app/services/wishlist.service';
import { first } from 'rxjs';

@Component({
    selector: 'app-profile-authentication-page',
    templateUrl: './profile-authentication-page.component.html',
    styleUrls: ['./profile-authentication-page.component.scss']
})
export class ProfileAuthenticationPageComponent implements OnInit {

    queryString = Object();
    rememberMe: boolean;
    resource: any = {};
    message: string;
    tokenValid = false;

    constructor(
        private router: Router,
        private alertService: AlertService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private userInfoService: UserInfoService,
        private storageService: StorageService,
        private resourceService: ResourceService,
        private wishListService: WishListService
    ) { }

    submittedLogin = false;
    loginForm: FormGroup;
    get f() { return this.loginForm.controls; }

    ngOnInit(): void {
        this.loadResoruce();
        this.resourceService.languageSubject.subscribe(response => {
            this.resource = response;
        });
        this.rememberMe = false;
        this.buildForm();
        this.queryString = this.activatedRoute.snapshot.queryParams;
        if (this.queryString?.token) {
            var token = JSON.parse(window.atob(this.queryString.token));
            this.loginService.verify_user(token).pipe(first()).subscribe((data: any) => {
                this.message = data.message;
                this.tokenValid = data.success;
            });
        } else {
            this.tokenValid = true;
        }
    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

    buildForm(): void {

        const accessTokenObj = this.storageService.getValue(StorageKey.authToken);
        // Retrieve rememberMe value from local storage
        const rememberMe = this.storageService.getValue(StorageKey.rememberMe);
        if (accessTokenObj && rememberMe == 'yes') {
            let userData = this.storageService.getValue(StorageKey.currentUser);
            this.userInfoService.setUserInfo(JSON.parse(userData));
            this.userInfoService.setLoginFlag(true);
            this.userInfoService.setToken(accessTokenObj);
            this.router.navigate(['/index']);
        } else {
            this.loginForm = this.formBuilder.group({
                email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
                password: ['', [Validators.required]],
                rememberMe: [false]
            });
        }
    }

    onSubmit() {
        this.submittedLogin = true;

        if (this.loginForm.invalid)
            return;

        var logininfo: any = {
            "email": this.f.email.value.trim(),
            "password": this.f.password.value,
        }

        const rememberMe = this.f.rememberMe.value;

        this.loginService.login(logininfo).subscribe((res: any) => {
            if (res.success) {
                let loginResponse = res.data;
                if (loginResponse.token) {
                    let userData = loginResponse.userInfo;

                    this.storageService.setValue(StorageKey.currentUser, JSON.stringify(userData));
                    this.storageService.setValue(StorageKey.authToken, loginResponse.token);
                    this.userInfoService.setUserInfo(userData);
                    this.userInfoService.setLoginFlag(true);
                    this.userInfoService.setToken(loginResponse.token);
                    this.storageService.loginSubject.next(true);

                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "countryId", userData?.countryId ? userData?.countryId : '');
                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "stateId", userData?.stateId ? userData?.stateId : '');
                    this.storageService.setGlobalSearch(StorageKey.globalSearch, "cityId", userData?.cityId ? userData?.cityId : '');

                    this.storageService.storageSubject.next(this.storageService.getGlobalSearch());

                    this.activatedRoute.queryParams.subscribe(params => {
                        let returnUrl = params['returnUrl'];
                        if (returnUrl) {
                            if (returnUrl.includes("wishlist")) {
                                var campaignData = JSON.parse(atob(returnUrl.split("?")[1].replace("wishlist=", '')));

                                var wishListdata = {
                                    campaignId: campaignData.campaignId,
                                    isActive: true,
                                }
                                this.wishListService.add(wishListdata).subscribe(async (wishListresponse: any) => {
                                    if (wishListresponse.success) {
                                        this.alertService.showSuccess(campaignData.campaignName + " " + this.resource.wishlist_add);
                                    }
                                    else {
                                        this.alertService.showError(wishListresponse.message);
                                    }
                                    this.router.navigate(['/deal']);
                                });
                            }
                            else {
                                const urlParts = returnUrl.split("?");
                                const baseUrl = urlParts[0];
                                let queryParams = {};

                                if (urlParts.length > 1) {
                                    const queryString = urlParts[1];

                                    // Split the query string by '&' to handle multiple query parameters
                                    const queryArray = queryString.split("&");

                                    queryArray.forEach(param => {
                                        const [key, value] = param.split("="); // Split each key-value pair
                                        if (key && value) {
                                            queryParams[key] = value; // Add to queryParams object
                                        }
                                    });
                                }
                                this.router.navigate([baseUrl], { queryParams });
                                // this.router.navigate([returnUrl.split("?")[0]], { queryParams: { campaign: returnUrl.split("?")[1].split("=")[1] } });
                            }
                        }
                        else {
                            this.router.navigate(['/deal']);
                        }
                    });
                } else {
                    this.alertService.showError("Sorry,it seems there is some technical/network error");
                    this.storageService.loginSubject.next(false);
                }
                //Set a cookie with the user credentials if the "Remember Me" checkbox is checked
                if (rememberMe) {
                    // this.cookieService.set('currentUser', JSON.stringify(logininfo), 7);
                    this.storageService.setValue(StorageKey.rememberMe, 'yes');
                }
            }
            else {
                this.storageService.loginSubject.next(false);
                this.alertService.showError(res.message);
            }
        },
            (err: any) => {
                this.storageService.loginSubject.next(false);
                this.alertService.showError(err.error.error.message);
            }
        )
    }
}